import React from "react";
import Layout from "../components/layout";
import Coursecard from "../components/cramcourse-cards";
import Card from 'react-bootstrap/Card';
import { Link } from "gatsby"

export default () => (
  <Layout>
  <div className="headerShadow"></div>
  <section className="homeBanner">
    <div class="container">
  		<div class="row">
  			<div class="col-lg-12">
  			    <div class="banner-style-one">
  						<div class="row home-content">
  						    <div class="col-lg-12 text-center">
  						        <h1 class="banner-title">LEARN THE SKILLS THAT MAKE YOU IN-DEMAND</h1>
  						        <p>Enroll in classroom, live and self-paced tech courses designed for industry requirements and to make you an in-demand pro.</p>
  						        <div class="btn-block"><Link className="banner-btn" to="/">Get Started</Link></div>
  						        <div class="ourStudents">
  						        	<h4>Our students work in</h4>
                        <img src="./images/learnercompanies/Companies_1.png" alt="Companies our students work | Crampete" />
  						        </div>
  						    </div>
  						</div>
  					</div>
  			    </div>
  			</div>
  	</div>
  </section>
  <section id="top-courses" class="top-courses">
		<div class="container">
			<div class="row">
				<div class="col-lg-6 offset-lg-3">
					<div class="main-title text-center">
						<h3 class="mt0">Browse Our Top Programs</h3>
						<p>All our programs are designed based on the industry requirements by out expert mentors.</p>
					</div>
				</div>
			</div>
			<div class="row">
			 	<div class="col-lg-12">
			 		<div class="emply-text-sec">
            <Coursecard></Coursecard>
				</div>
			</div>
    </div>
		</div>
	</section>
  <section class="dreamJobSection">
 <div class="container">
	<div class="row">
		<div class="col-lg-6 offset-lg-3">
			<div class="main-title text-center">
				<h3 class="mt0">Maximum learning in minimum time</h3>
			</div>
		</div>
	</div>
  <div class="row">
     <div class="col-md-4 col-sm-6">
       <Card className="cardShadow">
         <Card.Img variant="top" src="./images/home/highlights/mentor.jpg" />
         <Card.Body>
           <Card.Title>Well-trained mentors</Card.Title>
           <Card.Text>
           Our mentors are handpicked for effective teaching, student service and expertise. Whatever level of proficiency you have with this skill, they will help you build upon that - really fast.
           </Card.Text>
         </Card.Body>
       </Card>
     </div>
    <div class="col-md-4 col-sm-6">
      <Card className="cardShadow">
        <Card.Img variant="top" src="./images/home/highlights/project-based.jpg" />
        <Card.Body>
          <Card.Title>Project-based Learning Supported</Card.Title>
          <Card.Text>
          Get intimate and proficient with fundamentals, programming languages, frameworks and methods through hands-on labs, mini projects and a capstone project.
          </Card.Text>
        </Card.Body>
      </Card>
    </div>
   <div class="col-md-4 col-sm-6">
     <Card className="cardShadow">
       <Card.Img variant="top" src="./images/home/highlights/career_assistance.jpg" />
       <Card.Body>
         <Card.Title>Career Assistance</Card.Title>
         <Card.Text>
         We’ll pair you with a career counsellor to build your resume, give you interview coaching and get you calls for interviews from well-known organizations around you.
         </Card.Text>
       </Card.Body>
     </Card>
   </div>
	 </div>
 </div>
</section>
<section class="placementProcessSect">
  <div class="container">
	<div class="row">
	<div class="col-lg-6 offset-lg-3">
		<div class="main-title text-center">
			<h3 class="mt0">Placement Process for Cramdegree Students</h3>
		</div>
	</div>
	</div>
  <div class="placementOuter">
	   <div class="row">
	     <div class="col-md-8">
		   <div class="placementBox">
		     <img class=" ls-is-cached lazyloaded" src="./images/home/placementprocess/Porfolio.png" alt="Porfolio" />
		      <h4>Portfolio building</h4>
			  <p>Using the five mini-projects and the capstone project that you complete in this course, we help you put together an excellent project portfolio that will help greatly in your job search.</p>
		   </div>
		   <div class="placementBox">
		    <img class=" ls-is-cached lazyloaded" src="./images/home/placementprocess/Resume.png" alt="Resume" />
		      <h4>Resume building</h4>
			  <p>In order to apply for jobs, you need an attractive resume that rounds out all your skills in one place. We help you here as well.</p>
		   </div>
		   <div class="placementBox">
		   <img class=" ls-is-cached lazyloaded" src="./images/home/placementprocess/Interview.png" alt="Interview" />
		      <h4>Mock interviews</h4>
			  <p>Before attending interview calls, we equip with the soft skills and job skills through mock interviews.</p>
		   </div>
		   <div class="placementBox">
		   <img class=" ls-is-cached lazyloaded" src="./images/home/placementprocess/Call.png" alt="Call" />
		      <h4>Interview calls</h4>
			  <p>After you acquire all the necessary skills to be a great full stack web developer, we refer you to exciting jobs near you</p>
		   </div>

		 </div>
		 <div class="col-md-4">
		   <div class="placementSidebar">

       <div class="item">
       				<img class=" ls-is-cached lazyloaded" src="./images/testimonial/Alan.jpg" alt="" />
       				<p>Crampete's Full Stack Web Development is the program you need to take to become a great programmer. It teaches a lot of programming languages like HTML, CSS, JavaScript, MongoDB, DynamoDB, React JS, AJAX, and so on. I first learned front-end development, then back-end development, and then backend development.
       Tri Thanh Alan joined Konexaa as a Software Developer.</p>
       				<p><b>Tri Thanh Alan</b> <br />Student</p>
       				</div>
        </div>
		 </div>
	   </div>
	</div>
  </div>
</section>
<section id="our-partners" class="our-partners ourLearners">
		<div class="container">
    <div class="row">
  		<div class="col-lg-6 offset-lg-3">
  			<div class="main-title text-center">
  				<h3 class="mt0">Our learners come from</h3>
  			</div>
  		</div>
  	</div>
			<ul class="learnerImgMenu">
					  <li>
					   <img class=" ls-is-cached lazyloaded" src="./images/learnercompanies/Black_Hinduja.png" alt="" />
					  </li>
					  <li>
					   <img class=" ls-is-cached lazyloaded" src="./images/learnercompanies/Black_iopex.png" alt="" />
					  </li>
					  <li>
					   <img class=" ls-is-cached lazyloaded" src="./images/learnercompanies/Black_Lynks.png" alt="" />
					  </li>
					  <li>
					   <img class=" ls-is-cached lazyloaded" src="./images/learnercompanies/Black_Sulekha.png" alt="" />
					  </li>
					  <li>
					   <img class=" ls-is-cached lazyloaded" src="./images/learnercompanies/Black_UST-Global.png" alt="" />
					  </li>
					  <li>
					   <img class=" ls-is-cached lazyloaded" src="./images/learnercompanies/Ford.png" alt="" />
					  </li>
					  <li>
					   <img class=" ls-is-cached lazyloaded" src="./images/learnercompanies/Hexaware.png" alt="" />
					  </li>
					  <li>
					   <img class=" ls-is-cached lazyloaded" src="./images/learnercompanies/Hp.png" alt="" />
					  </li>
					  <li>
					   <img class=" ls-is-cached lazyloaded" src="./images/learnercompanies/NTT.png" alt="" />
					  </li>
					  <li>
					   <img class=" ls-is-cached lazyloaded" src="./images/learnercompanies/tcs.png" alt="" />
					  </li>
				</ul>
		</div>
	</section>
  <section>
	<div class="container-fluid">
		<div class="row ">
				<div class="col-sm-6 col-lg-6 col-xl-6">
						<div class="partnerOuter">
							<h3>Become our Hiring Partner</h3>
							<p>Access to ambitious, hard-working and committed professionals, rigorously trained by us in the hottest tech tracks – ready to join you.</p>
              <Link class="btnPartner" to="/about"><i class="fa fa-angle-double-right"></i>Hire Now <span class="flaticon-right-arrow-1"></span></Link>
						</div>
				</div>
				<div class="col-sm-6 col-lg-6 col-xl-6">
						<div class="partnerOuter">
							<h3>Own a Learning Centre</h3>
							<p>Contact us if you are interested to start Crampete learning centre in your location. We offer cutting edge tech courses through guided self paced and live streaming.</p>
              <Link class="btnPartner" to="/about"><i class="fa fa-angle-double-right"></i>Enquire Now <span class="flaticon-right-arrow-1"></span></Link>
					</div>
				</div>
			</div>
	</div>
	</section>
  </Layout>
)
